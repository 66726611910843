export const FLAGS = [
    { name: 'Afghanistan', id: 'af'},
    { name: 'Åland Islands', id: 'ax'},
    { name: 'Albania', id: 'al'},
    { name: 'Algeria', id: 'dz'},
    { name: 'American Samoa', id: 'as'},
    { name: 'Andorra', id: 'ad'},
    { name: 'Angola', id: 'ao'},
    { name: 'Anguilla', id: 'ai'},
    { name: 'Antarctica', id: 'aq'},
    { name: 'Antigua and Barbuda', id: 'ag'},
    { name: 'Argentina', id: 'ar'},
    { name: 'Armenia', id: 'am'},
    { name: 'Aruba', id: 'aw'},
    { name: 'Australia', id: 'au'},
    { name: 'Austria', id: 'at'},
    { name: 'Azerbaijan', id: 'az'},
    { name: 'Bahamas', id: 'bs'},
    { name: 'Bahrain', id: 'bh'},
    { name: 'Bangladesh', id: 'bd'},
    { name: 'Barbados', id: 'bb'},
    { name: 'Belarus', id: 'by'},
    { name: 'Belgium', id: 'be'},
    { name: 'Belize', id: 'bz'},
    { name: 'Benin', id: 'bj'},
    { name: 'Bermuda', id: 'bm'},
    { name: 'Bhutan', id: 'bt'},
    { name: 'Bolivia (Plurinational State of)', id: 'bo'},
    { name: 'Bonaire, Sint Eustatius and Saba', id: 'bq'},
    { name: 'Bosnia and Herzegovina', id: 'ba'},
    { name: 'Botswana', id: 'bw'},
    { name: 'Bouvet Island', id: 'bv'},
    { name: 'Brazil', id: 'br'},
    { name: 'Virgin Islands (British)', id: 'vg'},
    { name: 'British Indian Ocean Territory', id: 'io'},
    { name: 'Brunei Darussalam', id: 'bn'},
    { name: 'Bulgaria', id: 'bg'},
    { name: 'Burkina Faso', id: 'bf'},
    { name: 'Burundi', id: 'bi'},
    { name: 'Cambodia', id: 'kh'},
    { name: 'Cameroon', id: 'cm'},
    { name: 'Canada', id: 'ca'},
    { name: 'Cabo Verde', id: 'cv'},
    { name: 'Cayman Islands', id: 'ky'},
    { name: 'Central African Republic', id: 'cf'},
    { name: 'Chad', id: 'td'},
    { name: 'Chile', id: 'cl'},
    { name: 'China', id: 'cn'},
    { name: 'Hong Kong', id: 'hk'},
    { name: 'Macao', id: 'mo'},
    { name: 'Christmas Island', id: 'cx'},
    { name: 'Cocos (Keeling) Islands', id: 'cc'},
    { name: 'Colombia', id: 'co'},
    { name: 'Comoros', id: 'km'},
    { name: 'Congo', id: 'cg'},
    { name: 'Congo, Democratic Republic of the', id: 'cd'},
    { name: 'Cook Islands', id: 'ck'},
    { name: 'CostaRica', id: 'cr'},
    { name: 'Côte d\'Ivoire', id: 'ci'},
    { name: 'Croatia', id: 'hr'},
    { name: 'Cuba', id: 'cu'},
    { name: 'Curaçao', id: 'cw'},
    { name: 'Cyprus', id: 'cy'},
    { name: 'Czechia', id: 'cz'},
    { name: 'Czech Republic', id: 'cz'},
    { name: 'Denmark', id: 'dk'},
    { name: 'Djibouti', id: 'dj'},
    { name: 'Dominica', id: 'dm'},
    { name: 'Dominican Republic', id: 'do'},
    { name: 'Ecuador', id: 'ec'},
    { name: 'Egypt', id: 'eg'},
    { name: 'El Salvador', id: 'sv'},
    { name: 'Equatorial Guinea', id: 'gq'},
    { name: 'Eritrea', id: 'er'},
    { name: 'Estonia', id: 'ee'},
    { name: 'Ethiopia', id: 'et'},
    { name: 'Falkland Islands (Malvinas)', id: 'fk'},
    { name: 'Faroe Islands', id: 'fo'},
    { name: 'Fiji', id: 'fj'},
    { name: 'Finland', id: 'fi'},
    { name: 'France', id: 'fr'},
    { name: 'French Guiana', id: 'gf'},
    { name: 'French Polynesia', id: 'pf'},
    { name: 'French Southern Territories', id: 'tf'},
    { name: 'Gabon', id: 'ga'},
    { name: 'Gambia', id: 'gm'},
    { name: 'Georgia', id: 'ge'},
    { name: 'Germany', id: 'de'},
    { name: 'Ghana', id: 'gh'},
    { name: 'Gibraltar', id: 'gi'},
    { name: 'Greece', id: 'gr'},
    { name: 'Greenland', id: 'gl'},
    { name: 'Grenada', id: 'gd'},
    { name: 'Guadeloupe', id: 'gp'},
    { name: 'Guam', id: 'gu'},
    { name: 'Guatemala', id: 'gt'},
    { name: 'Guernsey', id: 'gg'},
    { name: 'Guinea', id: 'gn'},
    { name: 'Guinea-Bissau', id: 'gw'},
    { name: 'Guyana', id: 'gy'},
    { name: 'Haiti', id: 'ht'},
    { name: 'Heard Island and McDonald Islands', id: 'hm'},
    { name: 'Holy See', id: 'va'},
    { name: 'Honduras', id: 'hn'},
    { name: 'Hungary', id: 'hu'},
    { name: 'Iceland', id: 'is'},
    { name: 'India', id: 'in'},
    { name: 'Indonesia', id: 'id'},
    { name: 'Iran (Islamic Republic of)', id: 'ir'},
    { name: 'Iraq', id: 'iq'},
    { name: 'Ireland', id: 'ie'},
    { name: 'Isle of Man', id: 'im'},
    { name: 'Israel', id: 'il'},
    { name: 'Italy', id: 'it'},
    { name: 'Jamaica', id: 'jm'},
    { name: 'Japan', id: 'jp'},
    { name: 'Jersey', id: 'je'},
    { name: 'Jordan', id: 'jo'},
    { name: 'Kazakhstan', id: 'kz'},
    { name: 'Kenya', id: 'ke'},
    { name: 'Kiribati', id: 'ki'},
    { name: 'Korea (Democratic People\'s Republic of)', id: 'kp'},
    { name: 'Korea, Republic of', id: 'kr'},
    { name: 'Kuwait', id: 'kw'},
    { name: 'Kyrgyzstan', id: 'kg'},
    { name: 'Lao People\'s Democratic Republic', id: 'la'},
    { name: 'Latvia', id: 'lv'},
    { name: 'Lebanon', id: 'lb'},
    { name: 'Lesotho', id: 'ls'},
    { name: 'Liberia', id: 'lr'},
    { name: 'Libya', id: 'ly'},
    { name: 'Liechtenstein', id: 'li'},
    { name: 'Lithuania', id: 'lt'},
    { name: 'Luxembourg', id: 'lu'},
    { name: 'North Macedonia', id: 'mk'},
    { name: 'Madagascar', id: 'mg'},
    { name: 'Malawi', id: 'mw'},
    { name: 'Malaysia', id: 'my'},
    { name: 'Maldives', id: 'mv'},
    { name: 'Mali', id: 'ml'},
    { name: 'Malta', id: 'mt'},
    { name: 'Marshall Islands', id: 'mh'},
    { name: 'Martinique', id: 'mq'},
    { name: 'Mauritania', id: 'mr'},
    { name: 'Mauritius', id: 'mu'},
    { name: 'Mayotte', id: 'yt'},
    { name: 'Mexico', id: 'mx'},
    { name: 'Micronesia (Federated States of)', id: 'fm'},
    { name: 'Moldova, Republic of', id: 'md'},
    { name: 'Monaco', id: 'mc'},
    { name: 'Mongolia', id: 'mn'},
    { name: 'Montenegro', id: 'me'},
    { name: 'Montserrat', id: 'ms'},
    { name: 'Morocco', id: 'ma'},
    { name: 'Mozambique', id: 'mz'},
    { name: 'Myanmar', id: 'mm'},
    { name: 'Namibia', id: 'na'},
    { name: 'Nauru', id: 'nr'},
    { name: 'Nepal', id: 'np'},
    { name: 'Netherlands', id: 'nl'},
    { name: 'New Caledonia', id: 'nc'},
    { name: 'New Zealand', id: 'nz'},
    { name: 'Nicaragua', id: 'ni'},
    { name: 'Niger', id: 'ne'},
    { name: 'Nigeria', id: 'ng'},
    { name: 'Niue', id: 'nu'},
    { name: 'Norfolk Island', id: 'nf'},
    { name: 'Northern Mariana Islands', id: 'mp'},
    { name: 'Norway', id: 'no'},
    { name: 'Oman', id: 'om'},
    { name: 'Pakistan', id: 'pk'},
    { name: 'Palau', id: 'pw'},
    { name: 'Palestine, State of', id: 'ps'},
    { name: 'Panama', id: 'pa'},
    { name: 'Papua New Guinea', id: 'pg'},
    { name: 'Paraguay', id: 'py'},
    { name: 'Peru', id: 'pe'},
    { name: 'Philippines', id: 'ph'},
    { name: 'Pitcairn', id: 'pn'},
    { name: 'Poland', id: 'pl'},
    { name: 'Portugal', id: 'pt'},
    { name: 'Puerto Rico', id: 'pr'},
    { name: 'Qatar', id: 'qa'},
    { name: 'Réunion', id: 're'},
    { name: 'Romania', id: 'ro'},
    { name: 'Russian Federation', id: 'ru'},
    { name: 'Rwanda', id: 'rw'},
    { name: 'Saint Barthélemy', id: 'bl'},
    { name: 'Saint Helena, Ascension and Tristan da Cunha', id: 'sh'},
    { name: 'Saint Kitts and Nevis', id: 'kn'},
    { name: 'Saint Lucia', id: 'lc'},
    { name: 'Saint Martin (French part)', id: 'mf'},
    { name: 'Saint Pierre and Miquelon', id: 'pm'},
    { name: 'Saint Vincent and the Grenadines', id: 'vc'},
    { name: 'Samoa', id: 'ws'},
    { name: 'San Marino', id: 'sm'},
    { name: 'Sao Tome and Principe', id: 'st'},
    { name: 'Saudi Arabia', id: 'sa'},
    { name: 'Senegal', id: 'sn'},
    { name: 'Serbia', id: 'rs'},
    { name: 'Seychelles', id: 'sc'},
    { name: 'Sierra Leone', id: 'sl'},
    { name: 'Singapore', id: 'sg'},
    { name: 'Sint Maarten (Dutch part)', id: 'sx'},
    { name: 'Slovakia', id: 'sk'},
    { name: 'Slovenia', id: 'si'},
    { name: 'Solomon Islands', id: 'sb'},
    { name: 'Somalia', id: 'so'},
    { name: 'South Africa', id: 'za'},
    { name: 'South Georgia and the South Sandwich Islands', id: 'gs'},
    { name: 'South Sudan', id: 'ss'},
    { name: 'Spain', id: 'es'},
    { name: 'Sri Lanka', id: 'lk'},
    { name: 'Sudan', id: 'sd'},
    { name: 'Suriname', id: 'sr'},
    { name: 'Svalbard and Jan Mayen', id: 'sj'},
    { name: 'Eswatini', id: 'sz'},
    { name: 'Sweden', id: 'se'},
    { name: 'Switzerland', id: 'ch'},
    { name: 'Syrian Arab Republic', id: 'sy'},
    { name: 'Syria', id: 'sy'},
    { name: 'Taiwan, Province of China', id: 'tw'},
    { name: 'Tajikistan', id: 'tj'},
    { name: 'Tanzania, United Republic of', id: 'tz'},
    { name: 'Thailand', id: 'th'},
    { name: 'Timor-Leste', id: 'tl'},
    { name: 'Togo', id: 'tg'},
    { name: 'Tokelau', id: 'tk'},
    { name: 'Tonga', id: 'to'},
    { name: 'Trinidad and Tobago', id: 'tt'},
    { name: 'Tunisia', id: 'tn'},
    { name: 'Turkey', id: 'tr'},
    { name: 'Turkmenistan', id: 'tm'},
    { name: 'Turks and Caicos Islands', id: 'tc'},
    { name: 'Tuvalu', id: 'tv'},
    { name: 'Uganda', id: 'ug'},
    { name: 'Ukraine', id: 'ua'},
    { name: 'United Arab Emirates', id: 'ae'},
    { name: 'United Kingdom of Great Britain and Northern Ireland', id: 'gb'},
    { name: 'United States of America', id: 'us'},
    { name: 'United States Minor Outlying Islands', id: 'um'},
    { name: 'Uruguay', id: 'uy'},
    { name: 'Uzbekistan', id: 'uz'},
    { name: 'Vanuatu', id: 'vu'},
    { name: 'Venezuela (Bolivarian Republic of)', id: 've'},
    { name: 'Viet Nam', id: 'vn'},
    { name: 'Virgin Islands (U.S.)', id: 'vi'},
    { name: 'Wallis and Futuna', id: 'wf'},
    { name: 'Western Sahara', id: 'eh'},
    { name: 'Yemen', id: 'ye'},
    { name: 'Zambia', id: 'zm'},
    { name: 'Zimbabwe', id: 'zw'},
    { name: 'Catalonia', id:'es-ca'},
    { name: 'England', id: 'gb-eng'},
    { name: 'Europe', id: 'eu'},
    { name: 'Galles', id: 'gb-wls'},
    { name: 'Kosovo', id: 'xk'},
    { name: 'Scotland', id: 'gb-sct'},
    { name: 'United Nations', id: 'un'}
]
