export const MATCHSTATS = {
  en: [
    { name: '@total', n: 'Total shots'	},
    { name: '@ongoal', n: 'Shots on target'	},
    { name: '@offgoal', n: 'Shots off target'	},
    { name: '@blocked', n:	'Blocked shots'	},
    { name: '@insidebox', n:	'Inside penalty area'	},
    { name: '@outsidebox', n:	'Outside penalty area'	},
    { name: 'fouls', n:	'Fouls'	},
    { name: 'corners', n:	'Corner kicks' },
    { name: 'offsides', n:	'Offsides' },
    { name: 'possestiontime', n:	'Ball possession (%)' },
    { name: 'yellowcards', n:	'Yellow Cards' },
    { name: 'redcards', n:	'Red Cards'	},
    { name: 'saves', n:	'Saves'	}
  ],
  id: [
    { name: '@total', n: 'Tembakan total'	},
    { name: '@ongoal', n: 'Tembakan tepat sasaran'	},
    { name: '@offgoal', n: 'Tembakan target'	},
    { name: '@blocked', n:	'Tembakan yang diblokir'	},
    { name: '@insidebox', n:	'Di dalam area penalti'	},
    { name: '@outsidebox', n:	'Di luar area penalti' },
    { name: 'fouls', n:	'Pelanggaran'	},
    { name: 'corners', n:	'Tendangan sudut'	},
    { name: 'offsides', n:	'Offsides' },
    { name: 'possestiontime', n:	'Penguasaan bola (%)'	},
    { name: 'yellowcards', n:	'Kartu Kuning'	},
    { name: 'redcards', n:	'Kartu Merah'	},
    { name: 'saves', n:	'Menghemat'	}
  ]
}
